<template>
  <div>
    <div class="index-banner-pc">
      <div class="image-container">
        <img src="../static/images/1.png" alt="" class="swiper" />
        <div class="bannerAsk" @click="askClick">立即咨询</div>
      </div>
    </div>

    <section class="ys-box pb90 pt60 bg-light-blue-item">
      <div class="container-custom wow fadeInUp">
        <div class="row">
          <div class="col-md-3 col-xs-6">
            <div class="ys-item text-center">
              <div class="icon">
                <img
                  src="../static/images/ys-icon-01.png"
                  alt="业务培训易上手"
                />
              </div>
              <h4 class="dark-blue">业务培训易上手</h4>
              <p>专业客服培训让您的工厂快速<br />上手使用</p>
            </div>
          </div>

          <div class="col-md-3 col-xs-6">
            <div class="ys-item text-center">
              <div class="icon">
                <img src="../static/images/ys-icon-02.png" alt="维护迭代升级" />
              </div>
              <h4 class="dark-blue">维护迭代升级</h4>
              <p>免费维护与更新为您的安心<br />使用保驾护航</p>
            </div>
          </div>

          <div class="col-md-3 col-xs-6">
            <div class="ys-item text-center">
              <div class="icon">
                <img src="../static/images/ys-icon-03.png" alt="品牌化部署" />
              </div>
              <h4 class="dark-blue">品牌化部署</h4>
              <p>自定义系统名称与LOGO<br />享受定制化服务</p>
            </div>
          </div>

          <div class="col-md-3 col-xs-6">
            <div class="ys-item text-center">
              <div class="icon">
                <img src="../static/images/ys-icon-04.png" alt="多平台支持" />
              </div>
              <h4 class="dark-blue">多平台支持</h4>
              <p>打通钉钉应用与微信小程序<br />按您的喜好部署系统</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="index-1 pt60">
      <div class="container-custom">
        <div class="row">
          <div class="col-md-12 text-center wow fadeInUp">
            <h2 class="pc">生产数字化，管理智能化</h2>
            <!--<p></p>-->
          </div>
        </div>
      </div>
      <div class="w100">
        <div class="container-custom">
          <div class="row">
            <div class="col-md-12 pt60 pb30 wow fadeInUp">
              <div class="row">
                <div class="col-md-6">
                  <div class="text-box pt30" style="text-align: left">
                    <h4>在线质量管理</h4>
                    <h3 class="newBlue">多种报工模式</h3>
                    <p>支持微信报工、扫码报工、</p>
                    <p>PDA手持终端报工、工业平板报工</p>

                    <a
                      href="http://www.hnpinxun.cn/"
                      target="_blank"
                      class="btn btn-dark-blue"
                      >咨询详情</a
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <img src="../static/images/ksh-1-2.png" alt="小工单" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w100 bg-light-blue-item">
        <div class="container-custom">
          <div class="row">
            <div class="col-md-12 pt60 pb30 wow fadeInUp">
              <div class="row">
                <div class="col-md-6 text-left">
                  <img
                    src="../static/images/ksh-1-1.png"
                    alt="mes软件系统厂商"
                  />
                </div>
                <div class="col-md-6">
                  <div
                    class="text-box pt30"
                    style="text-align: left; margin-left: 162px"
                  >
                    <h4>工单自动生成</h4>
                    <h3 class="newBlue">生产任务自动分配</h3>
                    <p>根据加工单生产产品，</p>
                    <p>自动生成流程卡，并下发相应生产任务</p>
                    <a
                      href="http://www.hnpinxun.cn/"
                      target="_blank"
                      class="btn btn-dark-blue"
                      >咨询详情</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w100">
        <div class="container-custom">
          <div class="row">
            <div class="col-md-12 pt60 pb30 wow fadeInUp">
              <div class="row">
                <div class="col-md-6">
                  <div class="text-box pt30" style="text-align: left">
                    <h4>工资绩效 实时统计</h4>
                    <h3 class="newBlue">计件工资自动核算</h3>
                    <p>自动计算计时计件工资，</p>
                    <p>一键生成相应工资报表</p>

                    <a
                      href="http://www.hnpinxun.cn/"
                      target="_blank"
                      class="btn btn-dark-blue"
                      >咨询详情</a
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <img
                    src="../static/images/ksh-1-3.png"
                    alt="生产派工管理系统"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="w100 pt60 pb30 index-num-box">
      <div class="container-custom">
        <div class="row">
          <h2 class="text-center white wow fadeInUp">创新生产，智在必得</h2>

          <div class="col-md-3 col-xs-6">
            <div class="index-num white text-center wow fadeInUp">
              <div class="num"><span>100</span><em>%</em></div>
              <p class="white">至高为您提升生产效率</p>
            </div>
          </div>

          <div class="col-md-3 col-xs-6">
            <div class="index-num white text-center wow fadeInUp">
              <div class="num"><span>50</span><em>%</em></div>
              <p class="white">至高为您提升协同效率</p>
            </div>
          </div>

          <div class="col-md-3 col-xs-6">
            <div class="index-num white text-center wow fadeInUp">
              <div class="num"><span>100</span><em>%</em></div>
              <p class="white">至高为您提升生产透明度</p>
            </div>
          </div>

          <div class="col-md-3 col-xs-6">
            <div class="index-num white text-center wow fadeInUp">
              <div class="num"><span>45</span><em>%</em></div>
              <p class="white">至高为您提升生产良品率</p>
            </div>
          </div>
        </div>
        <div class="askBtn" @click="askClick">立即咨询</div>
      </div>
    </section>
    <div class="section_32 flex-row">
      <div class="box_47 flex-col">
        <img
          class="image_42"
          referrerpolicy="no-referrer"
          src="../static/images/13.png"
        />
        <span class="text_104">河南品讯网络科技有限公司</span>
        <span class="text_105">0379-61118888</span>
        <span class="paragraph_25"
          >地址：河南省洛阳市老城区青年创业大厦24层</span
        >
      </div>
      <div class="box_48 flex-col"></div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106">产品体系</span>
        <span
          class="text_107"
          @click="goOther('http://www.hnpinxun.cn/yuanchengtakan.html')"
          >远程踏勘管理系统
        </span>
        <span class="text_107" @click="goOther('http://yt.hnpinxun.cn/')"
          >友推云·电商SaaS平台</span
        >
        <span class="text_107" @click="goOther('https://fastrms.hnpinxun.cn/')"
          >视频资源管理AI工具</span
        >
        <span class="text_107" @click="goOther('http://hsxx.hnpinxun.cn/')"
          >亥时学习平台</span
        >
        <span class="text_107" @click="goOther('https://jifen.hnpinxun.cn/')"
          >文明积分银行</span
        >
        <span class="text_107" @click="goOther('https://linli.hnpinxun.cn/')"
          >邻里中心智慧平台</span
        >
      </div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106"></span>
        <span class="text_107" @click="goOther('https://gcsc.yt.hnpinxun.cn/')"
          >工厂生产管理系统</span
        >
        <span class="text_107" @click="goOther('https://jdgl.yt.hnpinxun.cn/')"
          >酒店系统</span
        >
        <span class="text_107" @click="goOther('https://dysbd.yt.hnpinxun.cn/')"
          >党员双报到</span
        >
        <span class="text_107" @click="goOther('https://hcjy.yt.hnpinxun.cn/')"
          >货车道路救援系统</span
        >
        <span class="text_107" @click="goOther('https://zs.yt.hnpinxun.cn/')"
          >招商宣传</span
        >
        <span class="text_107" @click="goOther('https://hw.yt.hnpinxun.cn/')"
          >智慧环卫综合平台</span
        >
      </div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106"></span>
        <span class="text_107" @click="goOther('https://jz.yt.hnpinxun.cn/')"
          >家政行业公共服务平台</span
        >
      </div>

      <div class="box_49 flex-col"></div>
      <div class="box_50 flex-col justify-between">
        <img
          class="image_43"
          referrerpolicy="no-referrer"
          src="../static/images/15.png"
        />
        <span class="text_120">微信小程序</span>
      </div>
      <div class="box_51 flex-col justify-between">
        <img
          class="image_44"
          referrerpolicy="no-referrer"
          src="../static/images/16.png"
        />
        <span class="text_121">微信公众号</span>
      </div>
    </div>
    <div class="text-wrapper_19 flex-col">
      <span class="text_44"
        >Copyright&nbsp;©&nbsp;2024&nbsp;河南品讯网络科技有限公司&nbsp;版权所有豫ICP备18009817号</span
      >
    </div>
  </div>
</template>

<script>
import WOW from "../static/js/wow";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  mounted() {
    new WOW().init();
  },
  methods: {
    askClick() {
      window.open("http://www.hnpinxun.cn/#/", "_blank");
    },
    goOther(e) {
      window.open(e, "_blank");
    },
  },
};
</script>

<style scoped>
@import "../static/css/style.css";
@import "../static/css/animate.min.css";
@import "../static/css/bootstrap.css";
@import "../static/css/font-awesome.css";
@import "../static/css/ionicon.css";
@import "../static/css/common.css";
.bannerAsk {
  width: 170px;
  height: 55px;
  border: 2px solid #ffffff;
  border-radius: 15px;
  font-size: 22px;
  font-family: MiSans, MiSans-400;
  font-weight: 400;
  text-align: CENTER;
  color: #ffffff;
  line-height: 50px;
  margin: 57px auto 20px auto;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translateX(-50%);
}
.askBtn {
  width: 160px;
  height: 50px;
  border: 2px solid #ffffff;
  border-radius: 15px;
  font-size: 18px;
  font-family: MiSans, MiSans-400;
  font-weight: 400;
  text-align: CENTER;
  color: #ffffff;
  line-height: 45px;
  margin: 57px auto 20px auto;
  cursor: pointer;
}
.askBtn:hover {
  transform: scale(1.1);
}
/*  */

.section_32 {
  background-color: rgba(34, 34, 34, 1);
  width: 100%;
  height: 17.63vw;
  justify-content: flex-center;
}

.box_47 {
  width: 14.12vw;
  height: 10.73vw;
  margin: 3.02vw 0 0 18.7vw;
}

.image_42 {
  width: 2.87vw;
  height: 2.87vw;
  margin-left: 5.58vw;
}

.text_104 {
  width: 13.96vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.04vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  margin-top: 0.73vw;
}

.text_105 {
  width: 14.02vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.56vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin-top: 0.94vw;
}

.paragraph_25 {
  width: 14.07vw;
  height: 1.88vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  line-height: 1.25vw;
  margin: 1.97vw 0 0 0.05vw;
}

.box_48 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.28vw 0 0 3.26vw;
}

.text-wrapper_38 {
  width: 5.68vw;
  height: 9.38vw;
  margin: 1.9vw 0 0 5.36vw;
}

.text_106 {
  width: 3.7vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_107 {
  width: 4.8vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.4vw 0 0 0.05vw;
  cursor: pointer;
}
.text_107:hover {
  color: #1a58d0;
}

.text-wrapper_39 {
  width: 3.7vw;
  height: 9.43vw;
  margin: 3.8vw 0 0 5.31vw;
}

.text_111 {
  width: 3.7vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_112 {
  width: 2.66vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.71vw 0 0 0.05vw;
}

.text_113 {
  width: 3.55vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.text_114 {
  width: 3.6vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.box_49 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.22vw 0 0 5.41vw;
}

.box_50 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 0 0 4.3vw;
}

.image_43 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_120 {
  width: 4.48vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}

.box_51 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 12.55vw 0 1.25vw;
}

.image_44 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_121 {
  width: 4.54vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}
.text-wrapper_19 {
  background-color: rgba(59, 59, 59, 1);
  height: 3.49vw;
  margin-top: -0.05vw;
  width: 100vw;
}

.text_44 {
  width: 28.6vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 35.67vw;
}
</style>
